import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../components/page/Header';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Card, Typography, styled, useTheme, useMediaQuery } from '@mui/material';
import { idiotData } from '../assets/sampleContent/idiotBadges';
import PersonaModal from '../components/sharedComponents/PersonaModal';

const StyledPageHeader = styled(Typography)({
    fontSize : "48px",
    fontWeight: "900",
    fontFamily: "'Anton', san-serif !important",
});
const StyledCarousel = styled(Carousel)({
    '& .react-multi-carousel-item' : {
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        height: "45vh"
    },
    '& .react-multi-carousel-track' : {
        alignItems : "center"
    },
    '& .react-multi-carousel-item--active' : {
        height: "60vh"
    },
    '& .react-multi-carousel-item div' : {
        height : "80%"
    }
});

const StyledCardTitle = styled(Typography)({
    fontSize : "1.5vw",
    fontWeight : "700",
    fontStyle : "italic",
    marginTop : "10px"
});

const StyledCardDesc = styled(Typography)({
    fontSize : "1vw",
    fontWeight : "400",
    fontStyle : "italic",
    marginTop : "15px"
});

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export default function Persona(){

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [modalOpen, setModalOpen] = useState(false);
    const [selectProfile, setSelectedProfile] = useState(''); 

    const handleModalClose = (status) => {
        setModalOpen(status);
    }

    const { idiotType } = useParams();
    
    useEffect(() => {
        if(idiotType){
            setSelectedProfile(idiotType);
            setModalOpen(true);
        }
    }, []);

    const handleCardClick = (title) => {
        setSelectedProfile(title);
        setModalOpen(true);
    }

    return (
        <>
            <PageHeader page='persona' />

            <Box style={{ padding : "5vh", textAlign: "center" }}>
                <StyledPageHeader>Take a look at other idiots...!</StyledPageHeader>
                <Typography>From yelling at the clouds to drinking the coolaid.</Typography>
            </Box>
                {
                    !isMobile &&
                    <Box sx={{ width: "90vw", margin: "auto" }}>
                        <StyledCarousel
                            swipeable={false}
                            draggable={false}
                            showDots={true}
                            responsive={responsive}
                            infinite={true}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            centerMode={true}
                            ssr={true}
                        >
                            {
                                idiotData.map((data, index) => 
                                    <Card key={index} className="personaPrevCards" onClick={() => handleCardClick(data.profile.title)}>
                                        <img src={data.profile.image} style={{ height : "40%" }} />
                                        <StyledCardTitle>{ data.profile.title }</StyledCardTitle>
                                        <StyledCardDesc>{ data.profile.quote }</StyledCardDesc>
                                    </Card>
                                )
                            }
                        </StyledCarousel>
                    </Box>
                }
            {
                isMobile && 
                idiotData.map((data, index) => 
                    <Card key={index} className="personaPrevCards" onClick={() => handleCardClick(data.profile.title)} sx={{ marginBottom : "15px", padding: "10px" }}>
                        <img src={data.profile.image} style={{ width : "40%" }} />
                        <Typography fontWeight={700}>{ data.profile.title }</Typography>
                        <Typography>{ data.profile.quote }</Typography>
                    </Card>
                )
            }
            <PersonaModal modalOpen={modalOpen} handleCloseModal={handleModalClose} idiotType={selectProfile} />
        </>
    );
}